<template>
  <div>
    <b-overlay
      :show="show"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <h6
                  class="text-primary"
                >
                  Tipo de Seguimiento
                </h6>
                <validation-provider
                  #default="{ errors }"
                  name="Tipo de Seguimiento"
                  rules="required"
                >
                  <v-select
                    v-model="seguimientoBt.tipo"
                    label="nombre"
                    :options="seguimiento"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="seguimientoBt.tipo">
            <b-col cols="12">
              <b-form-group>
                <h5 class="text-primary">
                  {{ seguimientoBt.tipo.id === 1 ? 'Número de Ticket' : ' Número de Caso' }}
                </h5>
                <validation-provider
                  #default="{ errors }"
                  name="Número de Ticket"
                  rules="required"
                >
                  <b-form-input
                    v-model="seguimientoBt.correlativo"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              sm="12"
            >
              <b-form-group>
                <h5 class="text-primary">
                  Comentarios
                </h5>
                <validation-provider
                  #default="{ errors }"
                  name="Comentarios"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="seguimientoBt.comentarios"
                    rows="2"
                    no-resize
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="12"
            >
              <h5 class="text-primary">
                Fotografía de Seguimiento
              </h5>
              <b-form-group>
                <imagen
                  :key="'fotografia-seguimiento'"
                  :leyenda="'fotografia-seguimiento'"
                  @cargar-file="imagenSeguimiento"
                />
              </b-form-group>
            </b-col>
            <b-col
              class="d-flex justify-content-end"
              cols="12"
            >
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Guardar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormTextarea, BButton, BForm, BFormInput, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import { getListadoFallas, updateCreateFalla } from '@/utils/mantenimiento/fallasBt/fallasBtUtils'
import { required } from '@validations'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import { saveImagen } from '@/utils/imagenes'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BForm,
    Imagen,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BOverlay,
    vSelect,
  },
  props: {
    falla: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      required,
      seguimientoBt: {
        tipo: null,
        comentarios: null,
        correlativo: null,
      },
      fotografiaSeguimiento: '',
      seguimiento: [],
      localization: null,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  async created() {
    console.log('falla', this.falla)
    this.seguimiento = getListadoFallas(2)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    imagenSeguimiento(file) {
      this.fotografiaSeguimiento = file
      console.log(file)
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          if (!this.fotografiaSeguimiento) {
            mensajeError('Debe Ingresar una Fotografía del Seguimiento!')
            return
          }
          this.show = true

          if (this.localization === null || this.localization === undefined) {
            mensajeError('Error obteniendo geolocalización, intente nuevamente!')
            return
          }

          const urlBucket = `fallas-bt/${this.falla.id}`
          const isMobile = window.screen.width <= 760
          const user = {
            id: this.usuario.id,
            nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
            name: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
            email: this.usuario.email,
            telefono: '',
          }

          const imagen = await saveImagen(urlBucket, this.localization, this.fotografiaSeguimiento, 'Foto Seguimiento', '', user, isMobile)

          if (!imagen) {
            mensajeError('Error almacenando la imagen, intente nuevamente')
            return
          }

          this.falla.seguimiento.push(imagen)
          this.falla.tracking.push({
            estado: 'FINALIZADA',
            horaFecha: new Date(),
          })
          this.falla.estado = 'FINALIZADA'
          this.falla.finalizacion = this.seguimientoBt

          if (await updateCreateFalla(this.falla, 1) !== null) {
            mensajeInformativo('Seguimiento Falla BT', 'Falla BT Actualizada Correctamente!!')
            this.$emit('reloadfallas')
          } else {
            mensajeError('Error actualizando la Falla, intente nuevamente')
          }
        }
      })
      this.show = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
